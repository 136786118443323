import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import DialogPeriodBase from './DialogPeriodBase';
//import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  objectName: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  objectsList: {
    width: '100%',
  },
  objectsListContainer: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

const textStrings = {
  speed: { title: 'Справка Скорост', explanation: 'справка за скоростта' },
  fuel: { title: 'Справка Гориво', explanation: 'справка за горивото' },
  print: { title: 'Справка Пътен Лист', explanation: 'пътен лист' },
  coverage: { title: 'Справка за Обработена Площ', explanation: 'справка за обработена площ' }
};

const ObjectListReportDialog = ({
  open,
  targets,
  type,
  state,
  onClose,
  onChange,
}) => {
  const classes = useStyles();
  const selectedIndex = React.useMemo(() => {
    if (state.reportObject === null) {
      return '';
    }
    return targets.findIndex(item => item.id === state.reportObject);
  }, [state, targets]);

  const elementList = React.useMemo(() => {
    const componentsList = targets.map((item, index) => {
      return (
        <MenuItem key={item.id} value={index}>
          {item.name}
        </MenuItem>
      );
    });
    return componentsList;
  }, [targets]);

  const title = type && textStrings[type].title;
  // const explanation = type && textStrings[type].explanation;

  const handleSelectObject = React.useCallback(
    ev => {
      const index = ev.target.value;
      const object = targets[index];
      onChange({ ...state, reportObject: object.id });
    },
    [onChange, state, targets],
  );

  // React.useEffect(() => {
  //   //setSelectedIndex('');
  //   onChange({ ...state, reportObject: null });
  // }, [targets]);

  const handleStartChange = date => {
    onChange({ ...state, reportStart: date });
  };

  const handleEndChange = date => {
    onChange({ ...state, reportEnd: date });
  };

  const handleClose = data => {
    if (data) {
      const object = targets[selectedIndex];
      onClose(
        Object.assign(
          {
            objectId: object.id,
            name: object.name,
            type: type,
          },
          data,
        ),
      );
    } else {
      onClose();
    }
  };

  const { reportStart, reportEnd } = state;

  return (
    <DialogPeriodBase
      open={open}
      title={title}
      disableApply={selectedIndex === ''}
      onClose={handleClose}
      startDate={reportStart}
      endDate={reportEnd}
      onStartChange={handleStartChange}
      onEndChange={handleEndChange}
    >
      <InputLabel id='object-list-label'>Изберете обект на справка:</InputLabel>
      <Select
        className={classes.objectsList}
        labelId='object-list-label'
        id='object-list'
        onChange={handleSelectObject}
        value={selectedIndex}
      >
        {elementList}
      </Select>
    </DialogPeriodBase>
  );
};

ObjectListReportDialog.propTypes = {
  open: PropTypes.bool,
  targets: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  state: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

export default ObjectListReportDialog;

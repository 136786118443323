import React from 'react';
import PropTypes from 'prop-types';
// import { EngineOnIcon, EngineOffIcon } from './CustomIcons.js';
// import MomentInfoBasic from './MomentInfoBasic';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import configuration from '../configuration';

const dateLocale = configuration().locale.date;

const useStyles = makeStyles(theme => ({
  name: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
  nameTypograpy: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  period: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 'bold',
  },
  adapters: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
  property: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  propsContainer: {
    paddingBottom: theme.spacing(1),
    minHeight: 0,
    overflowY: 'auto',
  },
}));

const MomentInfoCoverage = ({
  name,
  start,
  end,
  adapters,
  // startDist,
  // endDist,
  // totalDist,
  time,
  speed,
  // overtime,
  orientation = 'horizontal',
}) => {
  const classes = useStyles();
  const gridXs = orientation === 'vertical' ? 12 : 6;
  // const noDataSymbol = '-.-';
  // const kmLabel = 'км';

  const secondaryData = React.useMemo(() => {
    const labels = [];
    if(time){
      labels.push({
        label: 'Дата',
        value: new Date(time).toLocaleString(dateLocale),
      })
    }
    if(speed){
      labels.push({
        label: 'Скорост',
        value: `${Math.round(speed * 10) / 10} м²/сек`,
      })
    }
    return labels;
  }, [speed, time]);

  // const secondaryData = [
  //   {
  //     label: 'Километраж начало:',
  //     value: startDist + ' ' + kmLabel,
  //   },
  //   {
  //     label: 'Километраж край:',
  //     value: endDist + ' ' + kmLabel,
  //   },
  //   {
  //     label: 'Изминат път:',
  //     value: totalDist + ' ' + kmLabel,
  //   },
  //   {
  //     label: 'Извънреден труд:',
  //     value: overtime,
  //   },
  // ];

  // const primaryComponents = primaryData.map((item, index) => {
  //   return (
  //     <Grid key={index} item xs={gridXs} className={classes.property}>
  //       <Typography color='textSecondary' variant='body2'>
  //         {item.label}:
  //       </Typography>
  //       <Typography variant='body2'>{item.value}</Typography>
  //     </Grid>
  //   );
  // });

  const secondaryComponents = secondaryData.map((item, index) => {
    return (
      <Grid key={index} item sm={gridXs} className={classes.property}>
        <Typography color='textSecondary' variant='body2'>
          {item.label}
        </Typography>
        <Typography variant='body2'>{item.value}</Typography>
      </Grid>
    );
  });

  const adapterComponents = React.useMemo(() => {
    return adapters.map((adapter, index) => (
      <Grid key={index} item xs={12} className={classes.adapters}>
        <Typography variant='body2' style={{color: adapter.color, fontWeight: 'bold'}} display={'inline'}>
          {adapter.name}: {' '}
        </Typography>
        <Typography variant='body2' display={'inline'}> 
            {adapter.squareMeters * adapter.totalDistMeters} м² 
        </Typography>
      </Grid>
    ));
  }, [adapters, classes.adapters])

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} className={classes.name}>
          <Typography variant='body1' className={classes.nameTypograpy}>
            {name}
          </Typography>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} className={classes.period}>
            <Typography color='textSecondary' variant='body2'>
              {new Date(start).toLocaleString(dateLocale)} -{' '}
              {new Date(end).toLocaleString(dateLocale)}
            </Typography>
          </Grid>
        </Hidden>
      </Grid>
      <Grid container className={classes.propsContainer}>
        {adapterComponents}
        {/* {primaryComponents} */}        
      </Grid>
      <Hidden xsDown>
        <Divider variant='middle' />
        <Grid container>
          {secondaryComponents}
        </Grid>
      </Hidden>
    </React.Fragment>
  );
};

MomentInfoCoverage.propTypes = {
  name: PropTypes.string.isRequired,
  start: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  end: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  orientation: PropTypes.string,
  // startDist: PropTypes.number,
  // endDist: PropTypes.number,
  // totalDist: PropTypes.number,
  // overtime: PropTypes.string,
  // totalRest: PropTypes.string,
  // totalWork: PropTypes.string,
  // onDriverInput: PropTypes.func,
};

export default MomentInfoCoverage;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatDate, formatTimeInterval } from './../common';

const SQUARE_METERS_UNITS = 'м²';
const KILOMETERS_UNIT = 'км';

const DISTANCE_COLUMN_TYPE = 0;
const COVERAGE_COLUMN_TYPE = 1;

const useStyles = makeStyles({
  root: {
    '& button': {
      background: 'none!important',
      border: 'none',
      padding: '0!important',
      color: '#0000cd',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    overflow: 'scroll',
  },
});

const StyledTableCell = withStyles({
  root: {
    padding: '4px',
  },
})(TableCell);

const SkeletonTableRow = () => {
  return (
    <TableRow>
      <StyledTableCell component='th' scope='row'>
        <Skeleton variant='text' />
      </StyledTableCell>
      <StyledTableCell align='right'>
        <Skeleton variant='text' />
      </StyledTableCell>
    </TableRow>
  );
};

const PrintTableRowDaySummary = ({
  style,
  day,
  columns,
  onSelectItem,
  selected,
}) => {
  const cells = columns.map((item, index) => {
    return <TableCell key={index} style={{color: item.color}} align='right'>{item.data}</TableCell>
  })

  return (
    <TableRow selected={selected} onClick={onSelectItem} style={style}>
      <StyledTableCell component='th' scope='row'>
        {formatDate(day)}
      </StyledTableCell>
      {cells}
    </TableRow>
  );
};

PrintTableRowDaySummary.propTypes = {
  style: PropTypes.object,
  day: PropTypes.object,
  columns: PropTypes.array,
};

const getRowData = (dayData) => {
  const { date, geoJsonList } = dayData;
  const rowData = geoJsonList.map(item => item.properties);
  return {
    date: date,
    data: rowData,
  }
}

const getRowDataSummed = (rowData) => {
  const { date, data } = getRowData(rowData);
  const summed = data.reduce((map, item) => {
    const value = map.get(item.name);
    if(value){
      value.totalDistMeters += item.totalDistMeters;
      value.worktime += item.worktime;
    }else{
      map.set(item.name, item);
    }
    return map;
  }, new Map());

  return {
    data: summed,
    date: date,
    startIndex: rowData.startIndex,
    endIndex: rowData.endIndex,
  };
}

export const getColor = (dayList, name) => {
  for(let i = 0; i < dayList.length; i++){
    const day = dayList[i];
    const item = day.geoJsonList.find(item => item.properties.name === name);
    if(item){
      return item.properties.color;
    }
  }
}

const formatCoverageColumn = (value, color) => {
  return {
    data: `${value} ${SQUARE_METERS_UNITS}`,
    style: {
      color: color
    }
  }
}

const formatDistanceColumn = (value, color) => {
  return {
    data: `${value} ${KILOMETERS_UNIT}`,
    style: {
      color: color
    }
  }
}

const formatWorktimeColumn = (worktime, color) => {
  return {
    data: `${formatTimeInterval(worktime)}`,
    style: {
      color: color
    }
  }
}

const formatEmptyColumn = (color) => {
  return {
    data: undefined,
    style: {
      color: color
    }
  }
}

const CoverageReport = ({
  id,
  onSelectItem,
  dayList,
  selectedDay
}) => {
  const classes = useStyles();
  const countSkeletonItems = 3;
  const rowDataSummed = React.useMemo(() => {
    return dayList.map(item => getRowDataSummed(item));
  },[dayList])

  // const colorMap = React.useMemo(() => {
  //   return order.reduce((map, name) => {
  //     const color = getColor(dayList, name);
  //     map.set(name, color);
  //     return map;
  //   }, new Map());
  // }, [order, dayList])

  const order = React.useMemo(() => {
    const list = [];
    dayList.forEach(day => {
      day.geoJsonList.forEach(segment => {
        const index = list.findIndex(item => item.name === segment.properties.name);
        if(index === -1){
          if(segment.properties.squareMeters){
            list.push({
              name: segment.properties.name,
              color: segment.properties.color,
              type: COVERAGE_COLUMN_TYPE,
            })
          }else{
            list.push({
              color: segment.properties.color,
              type: DISTANCE_COLUMN_TYPE,
            })
          }  
        }
      })
    })
    const index = list.findIndex(item => item.type === DISTANCE_COLUMN_TYPE);
    if(index !== -1 && index !== 0){
      return [list[index], ...list.slice(0, index), ...list.slice(index + 1)]
    }else{
      return list;
    }
  }, [dayList])

  const tableBody = React.useMemo(() => {
    if (!dayList) {
      return Array.from(new Array(countSkeletonItems)).map((item, index) => {
        return <SkeletonTableRow key={index} />;
      });
    } else {
      return rowDataSummed.map((day, dayIndex) => {
        const { date, data } = day;      
        const columns = order.reduce(
          (list, item) => {
            const value = data.get(item.name);
            const emptyColumn = formatEmptyColumn(item.color);
            if(value){
              const { squareMeters, totalDistMeters, worktime, color } = data.get(item.name);
              if(item.type === COVERAGE_COLUMN_TYPE){
                return [
                  ...list, 
                  formatCoverageColumn(
                    squareMeters * totalDistMeters, 
                    color
                  ), 
                  formatWorktimeColumn(
                    worktime, 
                    color
                  ),
                ];  
              }else if(item.type === DISTANCE_COLUMN_TYPE) {
                return [
                  ...list, 
                  formatDistanceColumn(
                    totalDistMeters / 1000, 
                    color
                  ), 
                  formatWorktimeColumn(
                    worktime, 
                    color
                  ),
                ]; 
              }else{
                return list;
              }
            }else{
              return [...list, emptyColumn, emptyColumn];
            }
          }, []
        );
  
        const handleSelectItem = () => {
          onSelectItem({
            date: date,
            startIndex: day.startIndex,
            endIndex: day.endIndex,
          });
        }
  
        return (
          <PrintTableRowDaySummary
            key={-dayIndex}
            day={date}
            columns={columns}
            onSelectItem={handleSelectItem}
            selected={selectedDay.startIndex === day.startIndex && selectedDay.endIndex === day.endIndex}
          />
        )
      });
    }
  
  }, [dayList, rowDataSummed, order, selectedDay, onSelectItem])

  const dynamicHeaderColumns = React.useMemo(() => {
    return order.map((item, index) => {
      const style = {
        padding: '4px',
        color: item.color,
      }
      if(item.type === COVERAGE_COLUMN_TYPE){    
        return (
          <React.Fragment key={index}>
            <TableCell style={style} align='right'>Площ</TableCell>
            <TableCell style={style} align='right'>Моточасове</TableCell>
          </React.Fragment>
        );  
      }else{
        return (
          <React.Fragment key={index}>
            <TableCell style={style} align='right'>Разстояние</TableCell>
            <TableCell style={style} align='right'>Моточасове</TableCell>
          </React.Fragment>
        );          
      }
    });
  }, [order]);

  return (
    <TableContainer
      className={classes.root}
      id={id}
      component={Paper}
      style={{ maxHeight: '100%' }}
    >
      <Table size='small' aria-label='print report table' stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>Ден</StyledTableCell>
            {dynamicHeaderColumns}
          </TableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </TableContainer>
  );
};

CoverageReport.propTypes = {
  onSelectItem: PropTypes.func,
  options: PropTypes.object,
  id: PropTypes.string,
  dayList: PropTypes.array,
  selected: PropTypes.bool,
};

const CoverageReportSkeleton = () => {
  const classes = useStyles();
  return (
    <TableContainer
      className={classes.root}
      id={'coverage-report-table-skeleton'}
      component={Paper}
      style={{ maxHeight: '100%' }}
    >
      <Table size='small' aria-label='print report table' stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>Ден</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <SkeletonTableRow key={1} />
          <SkeletonTableRow key={2} />
          <SkeletonTableRow key={3} />
        </TableBody>
      </Table>
    </TableContainer>  
  )
}

export default CoverageReport;
export { CoverageReportSkeleton };

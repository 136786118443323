import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '@material-ui/core/styles';
import useMediaQueryMui from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import CoverageReport, { CoverageReportSkeleton } from './CoverageReport';
import MomentInfoCoverage from './MomentInfoCoverage';
import SkeletonInfo from './SkeletonInfo';
import LeafletMap from './Map';
import Track from './Map/Track';
import { polylinePalette } from './ObjectsView';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '50%',
    display: 'flex',
    'align-items': 'stretch',
    '@media (orientation: portrait)': {
      flexDirection: `column`,
    },
  },
  subContainer: {
    width: '100%',
    height: '50%',
  },
  info: {
    //width: '300px',
    flexGrow: 1,
    flexBasis: 0,
    '@media (orientation: portrait)': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 130,
    },    
  },
  map: {
    'flex-grow': 2,
    '-webkit-flex-grow': 2,
    display: 'flex',
    flex: 1,
  },
}));

const breakInterval = (interval, item) => {
  const front = item.from - interval.from;
  const end = interval.to - item.to;

  if(front === 0 && 0 < end){
    return [
      {...item }, 
      {...interval, from: item.to}
    ];
  }else if(0 < front && 0 === end){
    return [
      {...interval, to: item.from }, 
      {...item }
    ];
  }else{
    return [
      {...interval, to: item.from }, 
      {...item, }, 
      {...interval, from: item.to }
    ]
  }
}

export const insertAdapterInterval = (list, interval) => {
  const result = list.reduce((result, item) => {
    if(
      item.from <= interval.from && 
      interval.to <= item.to 
    ){
      return result.concat(breakInterval(item, interval))
    }else{
      result.push(item);
      return result;
    }

  }, []);

  return result;
};

export const orderAdapters = list => {
  return list.sort((left, right) => left.from < right.from ? -1 : 1);
}

export const checkOverlaps = list => {
  for(let i = 1; i < list.length; i++){
    if(list[i - 1].to > list[i].from){
      return true;
    }
  }
  return false;
}

export const fillGaps = (list, start, end) => {
  if(list.length === 0){
    return [];
  }
  const filled = [];
  const frontList = [];
  const endList = [];

  for(let i = 1; i < list.length; i++){
    filled.push({...list[i - 1]});
    if(list[i - 1].to - list[i].from < 0){
      filled.push({from: list[i - 1].to, to: list[i].from })
    }
  }
  filled.push({...list[list.length - 1]});
  const firstItem = filled[0];
  const lastItem = filled[filled.length -1];
  
  if(start < firstItem.from){
    frontList.push({from: start, to: firstItem.from});
  }
  if(lastItem.to < end){
    endList.push({from: lastItem.to, to: end});
  }

  return [...frontList, ...filled, ...endList];
}

const normalizeAdapters = (list, start, end) => {
  const ordered = orderAdapters(list);
  if(checkOverlaps(ordered)){
    throw new Error("Report's input data is malformed!");
  }

  return fillGaps(ordered, start, end);
}

export const getAdaptersSegment = (adaptersList, start, end) => {
  if(adaptersList.length === 0){
    return [{
      from: start,
      to: end
    }]
  }

  //let's deep copy array
  const list = adaptersList.map(item => ({...item}));
  const firstIndex = list.findIndex(item => item.from <= start && start < item.to);
  
  if(firstIndex >= 0){
    const firstItem = list[firstIndex];
    if(end <= firstItem.to){
      return [{...firstItem, from: start, to: end}]
    }
    const lastIndex = list.findIndex(item => item.from < end && end <= item.to);
    if(lastIndex >=0){
      const sliced = list.slice(firstIndex, lastIndex + 1);
      sliced[0].from = start;
      sliced[sliced.length - 1].to = end;
      return sliced;
    }else{
      throw new Error("End arguments must be a value inside the segment intervals!");
    }
  }else{
    throw new Error("Start arguments must be a value inside the segment intervals!");
  }
};

const getNextDayDate = date => {
  return new Date(
    date.getYear() + 1900,
    date.getMonth(),
    date.getDate() + 1,
    0,
    0,
    0,
    0
  );
}

//Let's iterate over timestamp
//1. Set nextDate timestamp, set startIndex to zero.
//2. Check if current timestamp is equal or greater than nextDate. 
//   If not skip doing anything, else return startIndex, endIndex (current value of i) startDate.
//   Set nextDate to startDate, startIndex to i, recalculate nextDate.

export const breakInDays = (timestamps) => {
  const days = [];
  let startDate = new Date(timestamps[0]);
  let nextDate = getNextDayDate(startDate);
  let startIndex = 0, i = 0;

  for(
    ;
    i < timestamps.length;
    i++
  ){
    if(nextDate.getTime() <= timestamps[i].getTime()){
      const endIndex = i;
      days.push({
        date: startDate,
        startIndex,
        endIndex
      })
      startIndex = i;
      startDate = nextDate;
      nextDate = getNextDayDate(startDate);
    }
  }

  days.push({
    date: startDate,
    startIndex: startIndex,
    endIndex: i
  })

  return days;
}

export const calcSegmentDistance = (distance, indexStart, indexEnd) => {
  let totalMeters = 0;
  const end = (indexEnd == undefined || indexEnd > distance.length) ? distance.length : indexEnd;
  for(let i = indexStart; i < end; i++){
    totalMeters += distance[i];
  }
  return totalMeters;
}

export const calcWorkTime = (distanceList, dateList, indexStart, indexEnd) => {
  let totalUseconds = 0;
  const lastIndex = (indexEnd == undefined || indexEnd >= distanceList.length) ? distanceList.length - 1: indexEnd;
  for(let i = indexStart + 1; i <= lastIndex; i++){
    if(distanceList[i]){
      totalUseconds += (dateList[i].getTime() - dateList[i - 1].getTime());
    }
  }
  return totalUseconds;
}

const calcGeoJsonDay = (selectedDayData, data, normalizedAdapers) => {
    const { geometry, properties } = data;
    const { startIndex, endIndex } = selectedDayData;
    // const normalized = normalizeAdapters(properties.adapters, 0, geometry.coordinates.length);
    const segments = getAdaptersSegment(normalizedAdapers, startIndex, endIndex);

    const adapters = segments.map((item, index) => {
      const coordinates = geometry.coordinates.slice(item.from, item.to + 1);
      const geojson = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: coordinates
        }
      }

      if(item.adapter && item.adapter.name){
        return {
          ...geojson, 
          properties: { 
            ...item.adapter,
            totalDistMeters: calcSegmentDistance(properties.distance, item.from, item.to + 1),
            // color: color, 
            worktime: calcWorkTime(properties.distance, properties.created, item.from, item.to + 1),
            startIndex: item.from,
          } 
        }
      }else{
        return {
          ...geojson, 
          properties: {
            totalDistMeters: calcSegmentDistance(properties.distance, item.from, item.to + 1),
            color: 'grey',
            worktime: calcWorkTime(properties.distance, properties.created, item.from, item.to + 1),
            startIndex: item.from,
          }
        }
      }
    });

    return adapters;
}

const areDatesEqual = (date1, date2) => {
  return (
    date1.getYear() === date2.getYear() 
    && date1.getMonth() === date2.getMonth() 
    && date1.getDate() === date2.getDate()
  )
}

const isValueInsideInterval = (value, a, b, delta = 0) => {
  if(a < b){
    if((a - delta) < value && value < (b + delta)){
      return true;
    }
  }else if(b < a){
    if((b - delta) < value && value < (a + delta)){
      return true;
    }
  }
  return false;
}

const findHorizontalIntervals = (coordinates, latlng) => {
  const deltaLat = 0.00001;  
  const set = new Set();
  for(let i = 1; i < coordinates.length; i++){
    if(isValueInsideInterval(latlng.lat, coordinates[i - 1][1], coordinates[i][1], deltaLat)){
      set.add(i - 1);
    }
  }
  return set;
}

const findVerticalIntervals = (coordinates, latlng) => {
  const deltaLng = 0.000015;
  const set = new Set();
  for(let i = 1; i < coordinates.length; i++){
    if(isValueInsideInterval(latlng.lng, coordinates[i - 1][0], coordinates[i][0], deltaLng)){
      set.add(i - 1);
    }
  }
  return set;
}

const findIntersections = (coordinates, latlng) => {
  const horizontalSet = findHorizontalIntervals(coordinates, latlng);
  const verticalSet = findVerticalIntervals(coordinates, latlng);
  const indexes = [...horizontalSet].filter(item => verticalSet.has(item));
  const intersections = indexes.map(item => [item, item + 1]);
  return intersections;
}

// const getPointDist = (point1, point2) => {
//   return Math.sqrt(Math.pow(point1.lat - point2.lat, 2) + Math.pow(point1.lng - point2.lng, 2));
// }

const coordsToLatLng = arrayPoint => {
  return {
    lng: arrayPoint[0], lat: arrayPoint[1]
  }
}

const findClosestLine = (coordinatePairs, latlng) => {
  const distances = coordinatePairs.map((item, index) => {
    const [point1, point2] = item;
    const distance = calcDistanceFromLine(coordsToLatLng(point1), coordsToLatLng(point2), latlng);
    if(distance !== NaN){
      return { distance, pairIndex: index }
    }else{
      return null;
    }
  });
  const minDistance = Math.min.apply(Math, distances.filter(item => item.distance).map(item => item.distance));
  return distances.find(item => item.distance === minDistance);
}

const findClickedPoint = (coordinates, latlng) => {
  const intersections = findIntersections(coordinates, latlng);
  if(intersections.length > 0){
    const coordinatePairs = intersections.map(item => {
      const [firstIndex, secondIndex] = item;
      return [
        coordinates[firstIndex],
        coordinates[secondIndex]
      ]
    })
    const { pairIndex } = findClosestLine(coordinatePairs, latlng);
    // const [firstPoint, secondPoint] = coordinatePairs[pairIndex];
    // const firstDist = getPointDist(coordsToLatLng(firstPoint), latlng);
    // const secondDist = getPointDist(coordsToLatLng(secondPoint), latlng);
    // if(firstDist < secondDist){
    //   return intersections[pairIndex][0];
    // }else{
    //   return intersections[pairIndex][1];
    // }
    return intersections[pairIndex][1];
  }
  // }else{
  //   return calcClosestPoint(coordinates, latlng);
  // }
}

// const calculateSurface = (pointA, pointB) => {
//   const sideA = pointA[0] - pointB[0];
//   const sideB = pointA[1] - pointB[1];
//   return Math.abs(sideA) * Math.abs(sideB);
// }

// const findClickedPoint2 = (coordinates, latlng) => {
//   const intersections = findIntersections(coordinates, latlng);
//   const surfaceList = intersections.map(item => {
//     const [firstIndex, secondIndex] = item;
//     return calculateSurface(coordinates[firstIndex], coordinates[secondIndex]);
//   })
//   const minSurface = Math.min.apply(Math, surfaceList.filter(item => item));
//   const index = surfaceList.findIndex(item => item === minSurface);
//   const clickedIndex = intersections[index];
//   if(clickedIndex[0] == 160){
//     debugger;
//     const wrongArea = calculateSurface(coordinates[160], coordinates[161]);
//     const rightArea = calculateSurface(coordinates[1475], coordinates[1476]);
//   }
//   return clickedIndex[0];
// }

const calcDistanceFromLine = (linePoint1, linePoint2, point) => {
  const x1 = linePoint1.lng;
  const y1 = linePoint1.lat;
  const x2 = linePoint2.lng;
  const y2 = linePoint2.lat;
  const x0 = point.lng;
  const y0 = point.lat;
  const distance = 
    Math.abs((x2 - x1) * (y1 - y0) - (x1 - x0) * (y2 - y1)) / 
    Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  return distance;
}

// const calcClosestPoint = (coordinates, latlng) => {
//   const squares = coordinates.map(item => Math.pow(item[0]-latlng.lng, 2) + Math.pow(item[1]-latlng.lat, 2))
//   const min = Math.min.apply(Math, squares);
//   return squares.findIndex(item => item === min);
// }

const calcCoverageSpeed = (createdArray, distanceArray, index, coverageRatio) => {
  const distance = distanceArray[index];
  const interval = (createdArray[index] - createdArray[index - 1]) / 1000;
  return (distance * coverageRatio) / interval;
}

const getTotalDist = (dayGeoJsonList, adapterName) => {
  return dayGeoJsonList.reduce((totalDistance, day) => {
    return day.geoJsonList.reduce((total, item) => {
      if(item.properties.name === adapterName){
        return item.properties.totalDistMeters + total;
      }
      return total;
    }, totalDistance);
  }, 0)
}

// const getTotalWorktime = (dayGeoJsonList, adapterName) => {
//   return dayGeoJsonList.reduce((totalWorktime, day) => {
//     return day.reduce((total, item) => {
//       if(item.properties.name === adapterName){
//         return item.properties.worktime + total;
//       }
//       return total;
//     }, totalWorktime);
//   }, 0)
// }

const calcAdaptersInfo = (normalized, dayGeoJsonList) => {
  if(!normalized){
    return [];
  }
  const list = normalized.map(item => {
    if(item.adapter){
      for(let i = 0; i < dayGeoJsonList.length; i++){
        const match = dayGeoJsonList[i].geoJsonList.find(
          segment => segment.properties.name === item.adapter.name
        );
        if(match){
          return { ...item.adapter, color: match.properties.color };
        }
      }  
    }
    return undefined;
  });
  const summed = list.filter(item => item && item.name).map(item => {
    return {
      ...item,
      totalDistMeters: getTotalDist(dayGeoJsonList, item.name),
    }
  });
  return summed;
}

const MapCoverageReport = ({
  params,
  data,
  selectedDayData,
  onSelectItem
}) => {
  const theme = useTheme();
  const isMobile = useMediaQueryMui(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const orientation = isPortrait ? 'horizontal' : 'vertical';
  const [momentData, setMomentData] = React.useState({});

  React.useEffect(() => {
    setMomentData({});
  }, [selectedDayData])

  const days = React.useMemo(() => {
    if(data){
      return breakInDays(data.properties.created);
    }else{
      return [];
    }
  }, [data]);

  const dayData = React.useMemo(() => {
    if(selectedDayData){
      return selectedDayData;
    }else if(days.length > 0){
      return days[0];
    }
    return undefined;
  }, [selectedDayData, days]);

  const normalized = React.useMemo(() => {
    if(data){
      const { geometry, properties } = data;
      if(properties.adapters){
        return normalizeAdapters(properties.adapters, 0, geometry.coordinates.length);  
      }
    }
    return [];
  }, [data])

  const dayGeoJsonList = React.useMemo(() => {
    const colorMap = new Map();
    if(data){
      return days.map(item => {
        const geoJsonList = calcGeoJsonDay(item, data, normalized).map(item => {
          if(item.properties.name){
            const name = item.properties.name;
            if(colorMap.has(name)){
              item.properties.color = colorMap.get(name);
            }else{
              const paletteIndex = (colorMap.size + 1) % polylinePalette.length;
              item.properties.color = polylinePalette[paletteIndex];
              colorMap.set(name, polylinePalette[paletteIndex]);
            }
          }
          return item;
        });
        return {
          ...item,
          geoJsonList: geoJsonList
        }
      });  
    }else{
      return [];
    }
  }, [days, data, normalized]);

  const dayGeoJson = React.useMemo(() => {
    if(dayData){
      const match = dayGeoJsonList.find(item => areDatesEqual(item.date, dayData.date));
      if(match){
        const { geoJsonList } = match;
        return geoJsonList;  
      }  
    }
    return [];

  }, [dayGeoJsonList, dayData])

  const track = React.useMemo(() => {
    const style = (feature) => {
      if(feature.properties){
        return {
          weight: 5,
          opacity: 0.65,
          color: feature.properties.color
        };  
      }else{
        return {
          weight: 3,
          opacity: 0.4,
          color: 'grey'
        };         
      }
    };

    const onClick = (obj) => {
      const {geometry, properties } = obj.target.feature;
      const { latlng } = obj;
      //const index = calcClosestPoint(geometry.coordinates, latlng);
      // const index = findClickedPoint(geometry.coordinates, latlng);
      const index = findClickedPoint(geometry.coordinates, latlng);
      const timestamp = data.properties.created[properties.startIndex + index]; 
      if(properties.squareMeters){
        const coverageSpeed = calcCoverageSpeed(
          data.properties.created, 
          data.properties.distance, 
          properties.startIndex + index,
          properties.squareMeters
        );
        setMomentData({
          coverageSpeed, 
          timestamp
        }) 
      } else {
        setMomentData({
          timestamp,
        }) 
      }
    }

    const refreshKey = (dayData && dayData.date) ? new Date(dayData.date).getTime() : 0;
    return <Track key={refreshKey} geojson={dayGeoJson} style={style} onClick={isMobile ? undefined : onClick}/>;
  }, [dayGeoJson, dayData, data, isMobile])

  const adaptersInfo = React.useMemo(() => {
    return calcAdaptersInfo(normalized, dayGeoJsonList);
  }, [normalized, dayGeoJsonList])

  return (
    <Box height={1} width={1}>
      <Box height={1} className={classes.container}>
        <Box className={classes.info}>
          <Paper
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            {!data ? (
              <SkeletonInfo orientation={orientation} />
            ) : (
              <MomentInfoCoverage
                name={data.properties.name}
                adapters={adaptersInfo}
                start={new Date(params.start)}
                end={new Date(params.end)}
                time={momentData.timestamp}
                speed={momentData.coverageSpeed}
                orientation={orientation}
                // onDriverInput={()=>{}}
              />
            )}
          </Paper>
        </Box>
        <Box className={classes.map}>
          <Paper style={{ flex: 1 }}>
            {!data ? (
              <LeafletMap></LeafletMap>
            ) : (
              <LeafletMap>
                {track}                
                {/* <Track geojson={{"type": "Feature", "geometry": { "coordinates": [[23.0639166, 42.6340233], [23.0633033, 42.63433]], type: 'LineString'}}} style={{weight: 10, color: 'green'}}/> */}
                {/* <Track geojson={{"type": "Feature", "geometry": { "coordinates": [[23.0633966, 42.634075], [23.0633966 + 0.000015, 42.634075 + 0.00001]], type: 'LineString'}}} style={{weight: 1, color: 'yellow'}}/> */}
              </LeafletMap>
            )}
          </Paper>
        </Box>
      </Box>
      <Box className={classes.subContainer}>
        <Paper style={{ width: '100%', height: '100%', position: 'relative' }}>
          {!data ? (
            <CoverageReportSkeleton />
          ) : (
            <CoverageReport
              id={'print-report'}
              onSelectItem={onSelectItem}
              dayList={dayGeoJsonList}
              selectedDay={dayData}
            />
          )}
        </Paper>
      </Box>
    </Box>
  );
};

MapCoverageReport.propTypes = {
  params: PropTypes.object,
  data: PropTypes.object,
  selectedDayData: PropTypes.object,
  onSelectItem: PropTypes.func,
};

export default MapCoverageReport;
